import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import Media from "react-media";
import { graphql } from "gatsby";

import { Query } from "react-apollo";
import gql from "graphql-tag";

// Containers
import { FlexContainer } from "../components/containers/flex-container";

// Components
import { LineItemDesktop } from "../components/basket/line-item-desktop";
import { LineItemTablet } from "../components/basket/line-item-tablet";
import { LineItemMobile } from "../components/basket/line-item-mobile";
import {
	CartHeaderDesktop,
	CartHeaderTablet,
	CartHeaderMobile,
} from "../components/basket/cart-headers";

// Context
import StoreContext from "../components/context/store";
import WindowWidthContext from "../components/context/window-width";
import { FooterColor } from "../components/context/footer-color";

// Styles
import { secondaryLight, tertiary } from "../components/utils/colors";

const Page = styled.div`
	margin: 150px 0 100px 0;

	@media (max-width: 900px) {
		margin: 100px 0 60px 0;
	}
`;

const CartContainer = styled.div`
	max-width: 100%;
	margin: 65px auto;

	@media (max-width: 768px) {
		margin: 65px 0 0 0;
	}

	& h1 {
		margin: 0 auto 18px auto;

		@media (max-width: 768px) {
			margin: 0 auto 16px auto;
		}
	}

	& .line-item {
		& p {
			margin: 0;
		}
	}
`;

const Totals = styled.div`
	display: grid;
	grid-template-columns: 8fr 476px;
	grid-gap: 30px;

	margin: 10px 0 0 0;

	& .checkout-button {
		&:hover {
			color: ${secondaryLight};
		}
	}

	& .shipping-disclaimer {
		margin: 10px 0 0 0;
		max-width: 690px;

		& p {
			margin: 1em 0;
		}

		& p:first-of-type {
			margin-top: 0;
		}

		& p:last-of-type {
			margin-bottom: 0;
		}
	}

	& .wrapper {
		margin: 10px 0 0 0;

		grid-column-start: 2;

		& > div {
			display: grid;
			grid-template-columns: 1fr 273px;

			margin: 0 0 5px 0;

			&:nth-of-type(3) {
				margin: 0;
			}
		}
	}

	& p {
		margin: 0;
	}

	& .total {
	}

	& a {
		margin: 30px 0 0 0;

		grid-column-start: 2;
	}

	.checkout-button {
		transition: 150ms color ease;

		@media (max-width: 1024px) {
			margin: 16px 0 0 0;
		}
	}

	@media (max-width: 1024px) {
		grid-template-columns: 1fr;

		& .wrapper {
			grid-column-start: 1;

			& > div {
				grid-template-columns: 1fr 169px;
				grid-gap: 20px;
			}

			& > div.checkout {
				display: block;
				margin: 20px 0 0 0;
			}
		}
	}
`;

const CUSTOMER_INFO = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			email
			firstName
			lastName
			tags
		}
	}
`;

const BasketContent = ({ isTrade, basketData }) => {
	const storeContext = useContext(StoreContext);
	const windowWidth = useContext(WindowWidthContext);
	const { checkout } = storeContext;

	const [checkoutHasDiscount, setCheckoutHasDiscount] = useState(null);
	const [originalCheckout, updateOriginalCheckout] = useState(checkout);

	useEffect(() => {
		updateOriginalCheckout(checkout);
	}, [checkout]);

	useEffect(() => {
		if (originalCheckout.lineItems.length >= 1) {
			if (isTrade === true) {
				const checkoutId = checkout.id;
				const discountCode = basketData.trade_discount_code;

				// Add a discount code to the checkout
				storeContext.client.checkout
					.addDiscount(checkoutId, discountCode)
					.then((checkout) => {
						// Do something with the updated checkout
						setCheckoutHasDiscount(
							checkout.discountApplications[0].value.percentage
						);
						updateOriginalCheckout(checkout);
					});
			} else {
				const checkoutId = checkout.id;

				if (checkout.discountApplications.length >= 1) {
					// Remove a discount code to the checkout
					storeContext.client.checkout
						.removeDiscount(checkoutId)
						.then((checkout) => {
							// Do something with the updated checkout
							setCheckoutHasDiscount(null);
							updateOriginalCheckout(checkout);
						});
				} else {
					updateOriginalCheckout(checkout);
				}
			}
		}
	}, [checkout]);

	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	console.log(originalCheckout);

	return (
		<Page>
			<Helmet title={`Basket | Christian Watson`} />

			<FlexContainer cv={true}>
				<CartContainer>
					<Media
						queries={{
							medium: "(min-width: 961px)",
						}}
						defaultMatches={{
							medium: windowWidth === 961,
						}}
						render={() => (
							<>
								{originalCheckout.lineItems.length >= 1 ? (
									<>
										<CartHeaderDesktop />
										{originalCheckout.lineItems.map((line_item) => {
											return (
												<LineItemDesktop
													key={line_item.id.toString()}
													line_item={line_item}
												/>
											);
										})}
									</>
								) : (
									<div>
										<div
											className="cart-empty"
											dangerouslySetInnerHTML={{
												__html: basketData.basket_empty_text.html,
											}}
										/>
									</div>
								)}
							</>
						)}
					/>

					<Media
						queries={{
							medium: "(min-width: 700px) and (max-width: 960px)",
						}}
						defaultMatches={{
							medium: windowWidth === 700,
						}}
						render={() => (
							<>
								{originalCheckout.lineItems.length >= 1 ? (
									<>
										<CartHeaderTablet />
										{originalCheckout.lineItems.map((line_item) => {
											return (
												<LineItemTablet
													key={line_item.id.toString()}
													line_item={line_item}
												/>
											);
										})}
									</>
								) : (
									<div>
										<div
											className="cart-empty"
											dangerouslySetInnerHTML={{
												__html: basketData.basket_empty_text.html,
											}}
										/>
									</div>
								)}
							</>
						)}
					/>

					<Media
						queries={{ medium: "(max-width: 699px)" }}
						defaultMatches={{
							medium: windowWidth === 699,
						}}
						render={() => (
							<>
								{originalCheckout.lineItems.length >= 1 ? (
									<>
										<CartHeaderMobile />
										{originalCheckout.lineItems.map((line_item) => {
											return (
												<LineItemMobile
													key={line_item.id.toString()}
													line_item={line_item}
												/>
											);
										})}
									</>
								) : (
									<div>
										<div
											className="cart-empty"
											dangerouslySetInnerHTML={{
												__html: basketData.basket_empty_text.html,
											}}
										/>
									</div>
								)}
							</>
						)}
					/>

					{originalCheckout.lineItems.length >= 1 ? (
						<>
							<Totals>
								<div
									className="shipping-disclaimer"
									dangerouslySetInnerHTML={{
										__html: basketData.shipping_disclaimer.html,
									}}
								/>

								<div className="wrapper">
									{checkoutHasDiscount !== null && (
										<div>
											<p className="total caps">Trade Discount</p>
											<p className="total grid-width">{checkoutHasDiscount}%</p>
										</div>
									)}

									<div>
										<p className="total caps">Order Total</p>
										<p className="total grid-width">
											£{originalCheckout.totalPrice.amount}{" "}
											<span className="caps small-text">INC VAT</span>
										</p>
									</div>
									<div className="checkout">
										<a
											className="checkout-button caps"
											href={originalCheckout.webUrl}
										>
											Proceed To Checkout
										</a>
									</div>
								</div>
							</Totals>
						</>
					) : null}
				</CartContainer>
			</FlexContainer>
		</Page>
	);
};

const Basket = ({ data }) => {
	const { customerAccessToken } = useContext(StoreContext);
	const basketData = data.prismicBasket.data;

	if (
		customerAccessToken !== null &&
		customerAccessToken.customerAccessToken !== ""
	) {
		return (
			<Query
				query={CUSTOMER_INFO}
				variables={{
					customerAccessToken: customerAccessToken.accessToken,
				}}
			>
				{({ loading, error, data }) => {
					if (loading || error) {
						return <></>;
					}

					if (data.customer !== null) {
						const isTrade =
							data.customer.tags !== undefined
								? data.customer.tags.includes("trade") ||
								  data.customer.tags.includes("Trade")
								: false;

						return <BasketContent isTrade={isTrade} basketData={basketData} />;
					} else {
						return <BasketContent isTrade={false} basketData={basketData} />;
					}
				}}
			</Query>
		);
	} else {
		return <BasketContent isTrade={false} basketData={basketData} />;
	}
};

export default Basket;

export const query = graphql`
	{
		prismicBasket {
			data {
				basket_empty_text {
					html
				}
				shipping_disclaimer {
					html
				}
				trade_discount_code
			}
		}
	}
`;
