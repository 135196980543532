import React from "react";
import styled from "styled-components";

// Desktop
const CartHeaderDesktopContainer = styled.div`
	display: grid;
	grid-template-columns: 4fr 5fr 180px 100px 100px 13px;
	grid-gap: 30px;

	border-bottom: 1px solid black;
	margin: 0 auto;

	padding: 0 0 10px 0;

	& .qty-spacer {
		padding: 0 0 0 10px;
	}

	@media (max-width: 1024px) {
		grid-template-columns: 4fr 5fr 180px 100px 100px 13px;
		grid-gap: 20px;
	}
`;

export const CartHeaderDesktop = () => (
	<CartHeaderDesktopContainer>
		<div className="caps">Item</div>
		<div className="caps"></div>
		<div className="caps">
			<span className="qty-spacer">Qty</span>
		</div>
		<div className="caps">Unit</div>
		<div className="caps">Total</div>
	</CartHeaderDesktopContainer>
);

// Tablet
const CartHeaderTabletContainer = styled.div`
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 4fr 5fr 80px 137px 13px;

	border-bottom: 1px solid black;
	margin: 0 auto;

	padding: 0 0 10px 0;
`;

export const CartHeaderTablet = () => (
	<CartHeaderTabletContainer>
		<div className="caps">Item</div>
		<div className="caps"></div>
		<div className="caps">Unit</div>
		<div className="caps">Total</div>
		<div className="caps"></div>
	</CartHeaderTabletContainer>
);

// Mobile
const CartHeaderMobileContainer = styled.div`
	display: block;

	border-bottom: 1px solid black;
	margin: 0 auto;

	padding: 0 0 10px 0;
`;

export const CartHeaderMobile = () => (
	<CartHeaderMobileContainer>
		<div className="caps">Item</div>
	</CartHeaderMobileContainer>
);
