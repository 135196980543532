import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Styles
import { secondaryLight, secondaryDark } from "../utils/colors";

// Context
import StoreContext from "../context/store";

const GridOuter = styled.div`
	display: grid;
	grid-template-columns: 4fr 5fr 180px 100px 100px 13px;
	grid-gap: 30px;

	padding: 20px 0;

	border-bottom: 1px solid ${secondaryDark};

	& button {
		color: ${secondaryDark};
		transition: 150ms color ease;
		&:hover {
			color: ${secondaryLight};
		}
	}

	@media (max-width: 1024px) {
		grid-template-columns: 4fr 5fr 180px 100px 100px 13px;
		grid-gap: 20px;
	}
`;

const Column = styled.div`
	position: relative;
	padding: ${(props) => props.padding};

	& p {
		margin: 0;
	}
`;

const QuantityContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& button {
		padding: 0 10px;
	}

	& .update-quantity {
		margin: 0 0 0 auto;

		color: ${secondaryDark};
		transition: 150ms color ease;

		&:hover {
			color: ${secondaryLight};
		}
	}

	& .quantity-input {
		border: 0;
		-webkit-appearance: none;

		padding: 0;

		width: 30px;
		text-align: center;
	}
`;

export const LineItemDesktop = (props) => {
	const { line_item } = props;
	const context = useContext(StoreContext);
	const [itemQuantity, setItemQuantity] = useState(line_item.quantity);

	const imageItem = line_item.variant.image ? (
		<div>
			<Link to={`/products/${line_item.variant.product.handle}`}>
				<img
					src={line_item.variant.image.src}
					alt={line_item.variant.image.altText}
					loading="lazy"
				/>
			</Link>
		</div>
	) : null;

	const removeItem = () => {
		context.removeLineItem(context.client, context.checkout.id, line_item.id);
	};

	const increaseQuantity = () => {
		if (context.adding !== true) {
			context.updateLineItem(
				context.client,
				context.checkout.id,
				line_item.id,
				line_item.quantity + 1
			);
			setItemQuantity(line_item.quantity + 1);
		}
	};

	const decreaseQuantity = () => {
		if (context.adding !== true) {
			context.updateLineItem(
				context.client,
				context.checkout.id,
				line_item.id,
				line_item.quantity - 1
			);
			setItemQuantity(line_item.quantity - 1);
		}
	};

	const selectedOptions = line_item.variant.selectedOptions.map(
		(option, index) => (
			<p
				key={`${line_item.variant.product.id}_line_item_selected_option_${index}`}
			>
				{option.name}: {option.value}
			</p>
		)
	);

	const updateItemQuantity = () => {
		if (context.adding !== true) {
			context.updateLineItem(
				context.client,
				context.checkout.id,
				line_item.id,
				itemQuantity
			);
		}
	};

	return (
		<GridOuter>
			<Column>{imageItem}</Column>
			<Column>
				<Link
					className="product-link"
					to={`/products/${line_item.variant.product.handle}`}
				>
					<p className="product-title caps">{line_item.title}</p>
				</Link>

				<div className="">{selectedOptions}</div>
			</Column>

			<Column>
				<QuantityContainer>
					<button
						onClick={() => decreaseQuantity()}
						disabled={line_item.quantity === 1}
					>
						–
					</button>

					<input
						className="quantity-input"
						type="number"
						value={itemQuantity}
						onChange={(e) => setItemQuantity(e.target.value)}
					/>
					<button onClick={() => increaseQuantity()}>+</button>

					<button
						onClick={() => updateItemQuantity()}
						className="update-quantity"
					>
						Update
					</button>
				</QuantityContainer>
			</Column>

			<Column>
				<p>{`£${line_item.variant.price.amount}`}</p>
			</Column>

			<Column>
				<p>{`£${(line_item.quantity * line_item.variant.price.amount).toFixed(
					2
				)}`}</p>
			</Column>

			<Column>
				<button className="remove caps" onClick={removeItem}>
					✕
				</button>
			</Column>
		</GridOuter>
	);
};
