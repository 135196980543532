import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Styles
import { secondaryLight, secondaryDark } from "../utils/colors";

// Context
import StoreContext from "../context/store";

const GridOuter = styled.div`
	display: block;

	padding: 20px 0;

	border-bottom: 1px solid ${secondaryDark};

	& button {
		color: ${secondaryDark};
		transition: 150ms color ease;
		&:hover {
			color: ${secondaryLight};
		}
	}
`;

const Column = styled.div`
	position: relative;
	padding: ${(props) => props.padding};

	& p {
		margin: 0;
	}
`;

const QuantityContainer = styled.div`
	& .flex {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		& div {
			width: 100%;
		}

		& button {
			padding: 0 10px;

			&:first-of-type {
				padding: 0 10px 0 0;
			}
		}

		& .update-quantity {
			margin: 0 0 0 auto;

			color: ${secondaryDark};
			transition: 150ms color ease;
			display: block;

			&:hover {
				color: ${secondaryLight};
			}
		}

		& .quantity-input {
			border: 0;
			-webkit-appearance: none;

			padding: 0;

			width: 30px;
			text-align: center;
		}
	}
`;

const GridOptions = styled.div`
	display: grid;
	grid-column-gap: 20px;
	grid-template-columns: 100px 1fr;

	padding: 0 0 5px 0;
`;

const ProductDetails = styled.div`
	padding: 10px 0 20px 0;
`;

export const LineItemMobile = (props) => {
	const { line_item } = props;
	const context = useContext(StoreContext);
	const [itemQuantity, setItemQuantity] = useState(line_item.quantity);

	const imageItem = line_item.variant.image ? (
		<div>
			<Link to={`/products/${line_item.variant.product.handle}`}>
				<img
					src={line_item.variant.image.src}
					alt={line_item.variant.image.altText}
					loading="lazy"
				/>
			</Link>
		</div>
	) : null;

	const removeItem = () => {
		context.removeLineItem(context.client, context.checkout.id, line_item.id);
	};

	const increaseQuantity = () => {
		if (context.adding !== true) {
			context.updateLineItem(
				context.client,
				context.checkout.id,
				line_item.id,
				line_item.quantity + 1
			);
		}
	};

	const decreaseQuantity = () => {
		if (context.adding !== true) {
			context.updateLineItem(
				context.client,
				context.checkout.id,
				line_item.id,
				line_item.quantity - 1
			);
		}
	};

	const selectedOptions = line_item.variant.selectedOptions.map(
		(option, index) => (
			<p
				key={`${line_item.variant.product.id}_line_item_selected_option_${index}`}
			>
				{option.name}: {option.value}
			</p>
		)
	);

	const updateItemQuantity = () => {
		if (context.adding !== true) {
			context.updateLineItem(
				context.client,
				context.checkout.id,
				line_item.id,
				itemQuantity
			);
		}
	};

	return (
		<GridOuter>
			<Column>
				<Link
					className="product-link"
					to={`/products/${line_item.variant.product.handle}`}
				>
					{imageItem}
				</Link>
			</Column>

			<Column>
				<ProductDetails>
					<Link
						className="product-link"
						to={`/products/${line_item.variant.product.handle}`}
					>
						<p className="product-title caps">{line_item.title}</p>
					</Link>

					<div>{selectedOptions}</div>
				</ProductDetails>
			</Column>

			<Column>
				<div>
					<QuantityContainer>
						<GridOptions>
							<div>
								<p className="caps">QTY</p>
							</div>
							<div className="flex">
								<button
									onClick={() => decreaseQuantity()}
									disabled={line_item.quantity === 1}
								>
									–
								</button>

								<input
									className="quantity-input"
									type="number"
									value={itemQuantity}
									onChange={(e) => setItemQuantity(e.target.value)}
								/>

								<button onClick={() => increaseQuantity()}>+</button>

								<button
									onClick={() => updateItemQuantity()}
									className="update-quantity"
								>
									Update
								</button>
							</div>
						</GridOptions>
					</QuantityContainer>

					<GridOptions>
						<p className="caps">Unit</p>
						<p>{`£${line_item.variant.price}`}</p>
					</GridOptions>

					<GridOptions>
						<p className="caps">Total</p>
						<p>{`£${(line_item.quantity * line_item.variant.price).toFixed(
							2
						)}`}</p>
					</GridOptions>

					<div>
						<button className="remove caps" onClick={removeItem}>
							Remove
						</button>
					</div>
				</div>
			</Column>
		</GridOuter>
	);
};
